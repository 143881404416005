
    import { Component, Watch } from 'vue-property-decorator'
    import ComponentMessageContent from '@/components/messages/ComponentMessageContent.vue'
    import { Draft, IDraft, Message } from '@/models/data/message'
    import { debounce as Debounce } from 'typescript-debounce-decorator'
    import MessageRepository from '@/repositories/message-repository'
    import { ContentMode, MessageWriteMode } from '@/api/enums'
    import { Account } from '@/models/data/account'
    import { getModule } from 'vuex-module-decorators'
    import Accounts from '@/store/modules/accounts'
    import {
        Blockquote,
        Bold,
        BulletList,
        Code,
        HardBreak,
        Heading,
        History,
        HorizontalRule,
        Italic,
        Link,
        ListItem,
        OrderedList,
        Paragraph,
        Strike,
        TiptapVuetify,
        Underline
    } from 'tiptap-vuetify'
    import ComponentTransKomSearch from '@/components/common/ComponentTransKomSearch.vue'
    import ComponentTranslateBox from '@/components/common/ComponentTranslateBox.vue'
    import ComponentMessageAttachments from '@/components/messages/ComponentMessageAttachments.vue'
    import Notification from '@/lib/notifications'
    import Notifications from '@/lib/notifications'
    import Messages from '@/store/modules/messages'
    import ComponentConfiguration from '@/components/templates/ComponentConfiguration.vue'
    import { Template } from '@/models/data/template'
    import ComponentSnippets from '@/components/messages/ComponentSnippets.vue'
    import MessageWritePage from '@/views/abstract/message-write-page'
    import { HttpError } from '@magiczne/http-client'

    @Component({
        components: {
            ComponentConfiguration,
            ComponentTransKomSearch,
            ComponentTranslateBox,
            ComponentMessageContent,
            ComponentMessageAttachments,
            ComponentSnippets,
            TiptapVuetify
        }
    })
    export default class PageMessageWrite extends MessageWritePage {
        messagesModule = getModule(Messages, this.$store)
        accountsModule = getModule(Accounts, this.$store)

        message: Message = new Message()
        loading = true
        sending = false
        savingNote = false

        range: Range | null = null
        selection: Selection | null = null

        dialog: {
            template: boolean
            transkom: boolean,
            translate: boolean,
            snippets: boolean
        } = {
            template: false,
            transkom: false,
            translate: false,
            snippets: false
        }

        extensions = [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [
                Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }
            ],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ]

        draft: IDraft = new Draft()

        get messageId (): string {
            return this.$route.params.messageId
        }

        get mode (): string {
            return this.$route.params.mode || 'new'
        }

        get contentMode (): ContentMode {
            return ContentMode.VIEW
        }

        get accounts (): Array<Account> {
            return this.accountsModule.entries.data
        }

        get note (): string {
            return this.message.details.note.note
        }

        set note (value: string) {
            this.message.details.note.note = value
        }

        @Debounce(750, { leading: false })
        async updateNote (): Promise<void> {
            this.savingNote = true
            try {
                await this.messagesModule.updateNote(this.message)
                Notifications.success('Note saved')
            } catch (e) {
                Notifications.error('no to dupa...')
            } finally {
                this.savingNote = false
            }
        }

        async loadMessage (): Promise<void> {
            if (this.mode === 'new') {
                this.loading = false
                this.message = new Message()
                setTimeout(() => this.draft.from = this.accounts[0], 1000)
                return
            }

            this.loading = true
            this.message = await MessageRepository.show(this.$route.params.messageId)
            this.messagesModule.setWatchingEntry(this.message)

            this.draft.previousMessage = this.message
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.draft.from = this.message.details.account
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.draft.to = this.message.data.from ? this.message.data.from.mail : ''
            this.draft.mode = MessageWriteMode.NEW

            if (this.mode === 'reply') {
                this.draft.subject = `Re: ${this.message.data.subject}`
                this.draft.mode = MessageWriteMode.REPLY
            }

            if (this.mode === 'forward') {
                this.draft.subject = `Fwd: ${this.message.data.subject}`
                this.draft.mode = MessageWriteMode.FORWARD
            }

            this.loading = false
        }

        async send (): Promise<void> {
            this.sending = true
            try {
                await MessageRepository.send(this.draft)
                Notification.success(this.$t('messages.success.send') as string)
                this.draft = new Draft()
                this.$router.push({ name: 'messages.list' })
            } catch (e) {
                if (e instanceof HttpError && HttpError.isHttpError(e) && e.response !== undefined) {
                    const json = await e.response.json()

                    Notification.error(json.message)
                } else {
                    Notification.error(this.$t('messages.error.send') as string)
                    throw e
                }
            } finally {
                this.sending = false
            }
        }

        applyTemplate (template: Template): void {
            this.draft.body = template.content
            this.dialog.template = false
        }

        applySnippet (snippet: string): void {
            this.dialog.snippets = false

            const value = this.hydrateTemplate(snippet)

            this.draft.body = this.draft.body.replace('{}', value)
            // this.mountSelected(value)
        }

        mountSelected (value: string): void {
            this.dialog.transkom = false
            this.dialog.translate = false

            const sanitizedValue = value.replaceAll('\n', '<br>')
            setTimeout(() => this.focusContent(sanitizedValue), 200)
        }

        focusContent (value: string | null = null): void {
            const field = document.querySelector('.ProseMirror') as HTMLElement
            const doc = field.ownerDocument

            if (value !== null) {
                this.range?.deleteContents()
                this.range?.insertNode(doc.createRange().createContextualFragment(value))

                this.range?.setStartBefore(field.children[field.children.length - 1])
                this.selection?.removeAllRanges()
                this.selection?.addRange(this.range as Range)
            }
        }

        saveRange (element: HTMLElement): void {
            const doc = element.ownerDocument
            const win = doc.defaultView

            if (win?.getSelection) {
                const sel = win.getSelection()
                if (sel?.getRangeAt && sel?.rangeCount) {
                    this.range = sel.getRangeAt(0)
                    this.selection = sel
                }
            }
        }

        startTranskom (): void {
            this.dialog.transkom = !this.dialog.transkom
        }

        startDeepl (): void {
            this.dialog.translate = !this.dialog.translate
        }

        startTemplates (): void {
            this.dialog.template = true
        }

        startSnippets (): void {
            this.mountSelected('{}')
            this.dialog.snippets = !this.dialog.snippets
        }

        async mounted (): Promise<void> {
            await this.loadMessage()

            await this.$nextTick()
            const field = document.querySelector('.ProseMirror') as HTMLElement
            field.onkeyup = () => this.saveRange(field)
            field.onmouseup = () => this.saveRange(field)

            await this.searchAndSetCustomer()
            await this.searchAndSetOrder()

            window.addEventListener('keydown', (event: KeyboardEvent) => {
                if (event.key === 'k' && event.ctrlKey) {
                    event.preventDefault()
                    this.startTranskom()
                }

                if (event.key === 'q' && event.ctrlKey) {
                    event.preventDefault()
                    this.startDeepl()
                }

                if (event.key === 'e' && event.ctrlKey) {
                    event.preventDefault()
                    this.startTemplates()
                }

                if (event.key === 's' && event.ctrlKey) {
                    event.preventDefault()
                    this.focusContent()
                }

                if (event.key === 'g' && event.ctrlKey) {
                    event.preventDefault()
                    this.startSnippets()
                }
            })
        }

        @Watch('messageId')
        async onMessageIdChanged (): Promise<void> {
            if (this.messageId) {
                await this.loadMessage()
            }
        }
    }
