
    import Vue from 'vue'
    import { Component, Prop, Watch } from 'vue-property-decorator'
    import { cloneDeep } from 'lodash'

    @Component
    export default class ComponentSettingField extends Vue {
        @Prop() startValue!: object | null
        @Prop() itemText!: string | null
        @Prop() itemValue!: string | null
        @Prop() items!: Array<object> | null
        @Prop() loading!: boolean
        @Prop() matching!: boolean

        query = ''

        selecting = false

        value: object | null = {}

        menuProps = {
            closeOnClick: false,
            closeOnContentClick: false,
            disableKeys: true,
            openOnClick: false,
            maxHeight: 304,
            offsetY: true,
            offsetOverflow: true,
            transition: false
        }

        showStartValue (): string | number {
            if (this.startValue === null || this.itemText === null || this.itemText === '') {
                return 'nie określono'
            }

            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return this.startValue[this.itemText]
        }

        reset (): void {
            this.value = this.startValue
            this.selecting = false
            this.closeMenu()
        }

        accept (): void {
            this.$emit('accept', this.value)
            this.selecting = false
            this.closeMenu()
        }

        closeMenu (): void {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.$refs.selection.isMenuActive = false
        }

        openMenu (): void {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            this.$refs.selection.isMenuActive = true
        }

        mounted () {
            this.value = cloneDeep(this.startValue)
        }

        @Watch('query')
        onQueryChange (value: string): void {
            this.$emit('query-change', value)
        }

        @Watch('loading')
        onLoadingChange (value: boolean): void {
            this.menuProps.maxHeight = value ? 1 : 304
        }
    }
