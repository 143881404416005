import Vue from 'vue'
import { getModule } from 'vuex-module-decorators'
import Templates from '@/store/modules/templates'
import { Item, Order } from '@/models/data/order'
import { Customer } from '@/models/data/customer'
import Notifications from '@/lib/notifications'
import Messages from '@/store/modules/messages'
import { Message } from '@/models/data/message'

abstract class MessageWritePage extends Vue {
    templatesModule = getModule(Templates, this.$store)
    messagesModule = getModule(Messages, this.$store)

    loadingCustomers = true
    loadingOrders = true
    loadingMatchingCustomer = false
    loadingMatchingOrder = false

    get customers (): Array<Customer> {
        return this.templatesModule.customers
    }

    get selectedCustomer (): Customer | null {
        return this.templatesModule.selectedCustomer
    }

    get orders (): Array<Order> {
        return this.templatesModule.orders
    }

    get selectedOrder (): Order | null {
        return this.templatesModule.selectedOrder
    }

    get orderItems (): Array<Item> | null {
        if (this.selectedOrder === null) {
            return null
        }

        return this.templatesModule.selectedOrder?.items ?? null
    }

    get selectedOrderItem (): Item | null {
        return this.templatesModule.selectedOrderItem
    }

    get watchingMessage (): Message {
        return this.messagesModule.watchingEntry
    }

    hydrateTemplate = (content: string) => {
        const noDataPlaceholder = '<b>brak danych</b>'

        // I know, it's shitty. Gonna do it better, I swear
        // todo (@szymekjanaczek)
        if (this.templatesModule.selectedOrder) {
            if (this.templatesModule?.selectedOrderItem === undefined || this.templatesModule?.selectedOrderItem === null) {
                const entries = this.templatesModule.selectedOrder.items.map((item: Item): number => item.id)
                content = content.replace('{{produkt.numer}}', entries.join(', '))
            } else {
                const value = this.templatesModule?.selectedOrderItem ? this.templatesModule?.selectedOrderItem.id.toString() : noDataPlaceholder
                content = content.replace('{{produkt.numer}}', value)
            }

            if (this.templatesModule?.selectedOrderItem === undefined || this.templatesModule?.selectedOrderItem === null) {
                const entries = this.templatesModule.selectedOrder.items.map((item: Item): string => item.title)
                content = content.replace('{{produkt.tytuł}}', entries.join(', '))
            } else {
                content = content.replace('{{produkt.tytuł}}', this.templatesModule?.selectedOrderItem?.title ?? noDataPlaceholder)
            }

            content = content.replace('{{zakup.data}}', this.templatesModule.selectedOrder.ordered_at_date)
            content = content.replace('{{zakup.czas}}', this.templatesModule.selectedOrder.ordered_at_time)
            content = content.replace('{{zakup.zapłata.data}}', this.templatesModule.selectedOrder.paid_at_date ?? noDataPlaceholder)
            content = content.replace('{{zakup.zapłata.czas}}', this.templatesModule.selectedOrder.paid_at_time ?? noDataPlaceholder)
            content = content.replace('{{zakup.numer zamówienia}}', this.templatesModule.selectedOrder.order_number)

            content = content.replace('{{spedycja.spedytor}}', this.templatesModule.selectedOrder.shipment?.carrier ?? noDataPlaceholder)
            content = content.replace('{{spedycja.wysyłka.data}}', this.templatesModule.selectedOrder.shipment?.sent_at_date ?? noDataPlaceholder)
            content = content.replace('{{spedycja.wysyłka.czas}}', this.templatesModule.selectedOrder.shipment?.sent_at_time ?? noDataPlaceholder)
            content = content.replace('{{spedycja.numer paczki}}', this.templatesModule.selectedOrder.shipment?.tracking_number ?? noDataPlaceholder)
            content = content.replace('{{spedycja.status paczki}}', this.templatesModule.selectedOrder.shipment?.shipment_status ?? noDataPlaceholder)
            content = content.replace('{{spedycja.śledzenie link}}', this.templatesModule.selectedOrder.shipment?.shipping_tracking_url ?? noDataPlaceholder)
        }

        if (this.templatesModule?.selectedCustomer) {
            content = content.replace('{{zakup.klient.login}}', this.templatesModule?.selectedCustomer?.nickname ?? noDataPlaceholder)
            content = content.replace('{{zakup.klient.nazwa}}', this.templatesModule?.selectedCustomer?.name ?? noDataPlaceholder)
        }

        return content
    }

    setCustomer (customer: Customer): void {
        this.templatesModule.setSelectedCustomer(customer)

        // noinspection JSIgnoredPromiseFromCall
        this.searchOrders()

        // noinspection JSIgnoredPromiseFromCall
        // todo(@szymekjanaczek) fix it - it was causing problem with replacing correct customer assignation with -peterm...-
        // this.messagesModule.assignContractor(customer)
    }

    setOrder (order: Order): void {
        this.templatesModule.setSelectedOrder(order)
        this.templatesModule.setSelectedCustomer(order.customer)

        // noinspection JSIgnoredPromiseFromCall
        // todo(@szymekjanaczek) fix it - it was causing problem with replacing correct customer assignation with -peterm...-
        // this.messagesModule.assignOrder(order)
    }

    setOrderItem (item: Item): void {
        this.templatesModule.setSelectedOrderItem(item)
    }

    async searchCustomers (search: string): Promise<void> {
        this.loadingCustomers = true
        try {
            await this.templatesModule.searchCustomers(search)
        } catch (e) {
            Notifications.error('Wystapił problem w trakcie ładowania kontrahentów.')
        } finally {
            this.loadingCustomers = false
        }
    }

    async searchOrders (orderNumber?: string): Promise<void> {
        this.loadingOrders = true
        try {
            await this.templatesModule.searchOrders({
                orderNumber,
                customer: this.selectedCustomer ?? undefined
            })
        } catch (e) {
            Notifications.error('Wystapił problem w trakcie ładowania zamówień.')
        }
        this.loadingOrders = false
    }

    async searchAndSetCustomer (): Promise<void> {
        this.loadingMatchingCustomer = true
        const nickname = this.watchingMessage.details.contractor?.nickname

        if (nickname === null || nickname?.length <= 0) {
            return
        }

        await this.searchCustomers(nickname)
        if (this.templatesModule.customers[0]) {
            this.setCustomer(this.templatesModule.customers[0])
        }
        this.loadingMatchingCustomer = false
    }

    async searchAndSetOrder (): Promise<void> {
        this.loadingMatchingOrder = true
        const orderNumber = this.watchingMessage.details.order?.order_id ?? null

        if (orderNumber === null || orderNumber.length <= 0) {
            return
        }

        await this.searchOrders(orderNumber)
        if (this.templatesModule.orders[0]) {
            this.setOrder(this.templatesModule.orders[0])
        }
        this.loadingMatchingOrder = false
    }
}

export default MessageWritePage
