
    import { Component, Watch } from 'vue-property-decorator'
    import ComponentSettingField from '@/components/templates/ComponentSettingField.vue'
    import { Template } from '@/models/data/template'
    import { Item, Order } from '@/models/data/order'
    import { Customer } from '@/models/data/customer'
    import { getModule } from 'vuex-module-decorators'
    import { debounce as Debounce } from 'typescript-debounce-decorator'
    import Templates from '@/store/modules/templates'
    import { cloneDeep } from 'lodash'
    import {
        Blockquote,
        Bold,
        BulletList,
        Code,
        HardBreak,
        Heading,
        History,
        HorizontalRule,
        Italic,
        Link,
        ListItem,
        OrderedList,
        Paragraph,
        Strike,
        TiptapVuetify,
        Underline
    } from 'tiptap-vuetify'
    import Messages from '@/store/modules/messages'
    import MessageWritePage from '@/views/abstract/message-write-page'

    @Component({
        components: {
            ComponentSettingField,
            TiptapVuetify
        }
    })
    export default class ComponentConfiguration extends MessageWritePage {
        templatesModule = getModule(Templates, this.$store)
        messagesModule = getModule(Messages, this.$store)

        template = new Template()

        loading = {
            templates: true,
            customers: this.loadingCustomers,
            orders: this.loadingOrders,
            matchingCustomer: this.loadingMatchingCustomer,
            matchingOrder: this.loadingMatchingOrder
        }

        selection: {
            template: Template,
            customer: Customer | null,
            order: Order | null,
            item: Item | null
        } = {
            template: new Template(),
            customer: null,
            order: null,
            item: null
        }

        extensions = [
            History,
            Blockquote,
            Link,
            Underline,
            Strike,
            Italic,
            ListItem,
            BulletList,
            OrderedList,
            [
                Heading, {
                    options: {
                        levels: [1, 2, 3]
                    }
                }
            ],
            Bold,
            Code,
            HorizontalRule,
            Paragraph,
            HardBreak
        ]

        // region getters & setters
        get templates (): Array<Template> {
            return this.templatesModule.entries.data
        }
        // endregion

        // utility methods
        focusTemplateSelection () {
            const input = this.$refs['template-selection']
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            input.focus()
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            input.isMenuActive = true
        }
        // endregion

        // region setting params
        setTemplate (template: Template) {
            this.selection.template = cloneDeep(template)
        }
        // endregion

        refresh (): void {
            if (this.selection.template.id <= 0) {
                return
            }

            this.selection.template.content = this.hydrateTemplate(cloneDeep(this.selection.template.content))
        }

        setCustomerWrapper (customer: Customer): void {
            this.setCustomer(customer)
        }

        setOrderWrapper (order: Order): void {
            this.setOrder(order)
        }

        setOrderItemWrapper (item: Item) {
            this.setOrderItem(item)
        }

        apply (): void {
            this.$emit('apply', this.selection.template)

            this.selection = {
                template: new Template(),
                customer: null,
                order: null,
                item: null
            }
        }

        @Debounce(500, { leading: false })
        async onSearchCustomers (query: string): Promise<void> {
            await this.searchCustomers(query)
        }

        @Debounce(500, { leading: false })
        async onSearchOrders (query: string): Promise<void> {
            await this.searchOrders(query)
        }

        async mounted (): Promise<void> {
            this.loading.templates = true
            this.focusTemplateSelection()
            await this.templatesModule.loadPage()
            this.loading.templates = false
        }

        @Watch('customerNickname')
        async onCustomerNicknameChange (): Promise<void> {
            await this.searchAndSetCustomer()
        }

        @Watch('orderNumber')
        async onOrderNumberChange (): Promise<void> {
            await this.searchAndSetOrder()
        }
    }
